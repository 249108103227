/* Add to your existing custom-datepicker.css or relevant stylesheet */

.react-datepicker {
  font-family: inherit;
  border: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.react-datepicker__header {
  background-color: white;
  border-bottom: none;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.react-datepicker__navigation {
  top: 0.5rem;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.125rem;
  width: 2rem;  /* Matches w-8 */
  height: 2rem; /* Matches h-8 */
  line-height: 2rem;
  border-radius: 9999px;
}

.react-datepicker__day--disabled {
  color: #D1D5DB !important; /* gray-300 */
  cursor: not-allowed !important;
  background-color: transparent !important;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent !important;
}

.react-datepicker__day:not(.react-datepicker__day--disabled):hover {
  background-color: #EFF6FF; /* blue-50 */
}

.react-datepicker__day--selected {
  background-color: #3B82F6 !important; /* blue-500 */
  color: white !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #93C5FD; /* blue-300 */
  color: white;
}

.react-datepicker__input-container input {
  width: 100%;
  padding-left: 2.75rem;
}

.react-datepicker__close-icon {
  right: 0.5rem;
}

.react-datepicker__close-icon::after {
  background-color: #6B7280; /* gray-500 */
  font-size: 14px;
  padding: 0.15rem;
}

.react-datepicker__triangle {
  display: none;
}